import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { CustomSelect } from '../../../../components/CustomSelect/CustomSelect'
import { DataContext } from '../../../../context/DataContext'
import {
  cancelEdit,
  compareValues,
  fetchData,
  getDocTypesValues,
  getGendersValues,
  getLocationValues,
  handleSubmit
} from './EditPQRSUserFunctions'
import {
  calculateBirthdayDatesRange,
  onBirthdayDateChange,
  onDocumentNumberChange,
  onEmailChange,
  onExpeditionCityChange,
  onIsValidBirthdayDateBlur,
  onIsValidDocumentNumberBlur,
  onIsValidEmailBlur,
  onIsValidExpeditionCityBlur,
  onIsValidLastNameBlur,
  onIsValidNameBlur,
  onIsValidPhoneBlur,
  onLastNameChange,
  onNameChange,
  onPhoneChange
} from '../../../UsersAdmin/utils/createAndEditUserMethods'
import { maxSize } from '../../../../utils/validateFunctions'
import { useIncorrectInputFormat } from '../../../UsersAdmin/hooks/useIncorrectInputFormat'
import { useModal } from '../../../../hooks/useModal'
import backIcon from '../../../../assets/icons/icon_volver.svg'
import userPerson from '../../../../assets/images/userPerson.png'

import './EditPQRSUser.css'
import { Modal } from '../../../../components/Modal/Modal'
import { ScriptContext } from '../../../../context/ScriptsContext'

export const EditPQRSUser = ({ state }) => {
  const { id } = state
  const { scriptsSave } = useContext(ScriptContext)
  const dataContext = useContext(DataContext)
  const { dataState, dataDispatch } = dataContext
  const { idToken, citiesFetched, statesFetched, docTypesFetched, gendersFetched } = dataState.operative
  const navigate = useNavigate()
  const [showSaveModal, handleSaveModalShow] = useModal()
  const [showCancelModal, handleCancelModalShow, handleCancelModalClose] =
    useModal()

  const [initialValues, setinitialValues] = useState({})
  const [newValues, setNewValues] = useState({})
  const [citiesFiltered, setCitiesFiltered] = useState([])

  const [isDisabled, setIsDisabled] = useState(false)
  const [incorrectInputFormat, setIncorrectInputFormat] =
  useIncorrectInputFormat()
  const { minBirthdayDate, maxBirthdayDate } = useMemo(
    () => calculateBirthdayDatesRange(),
    []
  )

  useEffect(() => {
    fetchData({ idToken, id, setinitialValues, setNewValues, dataContext })
    getLocationValues({ citiesFetched, statesFetched, dataDispatch })
    getDocTypesValues({ docTypesFetched, dataDispatch })
    getGendersValues({ gendersFetched, dataDispatch })
  }, [])

  useEffect(() => {
    if (newValues.country) {
      const citiesFiltered = citiesFetched.filter(
        (city) =>
          city.stateId.stateId === newValues.country || newValues.country.id
      )
      setCitiesFiltered(citiesFiltered)
    }
  }, [newValues?.country])

  useEffect(() => {
    const valuesAreDifferent = compareValues(initialValues, newValues)
    valuesAreDifferent.length ? setIsDisabled(false) : setIsDisabled(true)
  }, [newValues])

  const handleIDType = (optionChoosed) =>
    setNewValues({ ...newValues, documentTypeId: optionChoosed })
  const handleGenderType = (optionChoosed) =>
    setNewValues({ ...newValues, gender: optionChoosed })
  const handleStateSelected = (optionChoosed) =>
    setNewValues({ ...newValues, country: optionChoosed })
  const handleCitySelected = (optionChoosed) =>
    setNewValues({ ...newValues, city: optionChoosed })

  return (
    <section id="EditPQRSUser">
      <img
        src={userPerson}
        className="EditPQRSUserImg"
        alt={scriptsSave && scriptsSave.userPQRSEditString ? scriptsSave.userPQRSEditString.altImageTxt1 : ''}
      />
      <div className="EditPQRSUserForm">
        <div className="EditPQRSUserLSide">
          <div className="EditPQRSUserDoubleInput">
            <div className="EditPQRSUserLSideFName">
              <p>{scriptsSave && scriptsSave.userPQRSEditString ? scriptsSave.userPQRSEditString.name : ''}</p>
              <input
                type="text"
                className={`${
                  incorrectInputFormat.nameFormat === 'employeeRightFormat'
                    ? 'editUserInput'
                    : 'editUserInputIncorrectFormat'
                }`}
                value={newValues.name}
                onChange={(e) => onNameChange(e, newValues, setNewValues)}
                onInput={(e) => maxSize(e, 'flName')}
                onBlur={() =>
                  onIsValidNameBlur(
                    newValues,
                    incorrectInputFormat,
                    setIncorrectInputFormat
                  )
                }
              />
              <span className={incorrectInputFormat.nameFormat}>
                {incorrectInputFormat.nameMessage}
              </span>
            </div>
            <div className="EditPQRSUserLSideLName">
              <p>{scriptsSave && scriptsSave.userPQRSEditString ? scriptsSave.userPQRSEditString.lastname : ''}</p>
              <input
                type="text"
                className={`${
                  incorrectInputFormat.lastNameFormat === 'employeeRightFormat'
                    ? 'editUserInput'
                    : 'editUserInputIncorrectFormat'
                }`}
                value={newValues?.lastName}
                onChange={(e) => onLastNameChange(e, newValues, setNewValues)}
                onInput={(e) => maxSize(e, 'flName')}
                onBlur={() =>
                  onIsValidLastNameBlur(
                    newValues,
                    incorrectInputFormat,
                    setIncorrectInputFormat
                  )
                }
              />
              <span className={incorrectInputFormat.lastNameFormat}>
                {incorrectInputFormat.lastNameMessage}
              </span>
            </div>
          </div>
          <div className="EditPQRSUserDoubleInput2">
            <div>
              <p>{scriptsSave && scriptsSave.userPQRSEditString ? scriptsSave.userPQRSEditString.typeId.title : ''}</p>
              <CustomSelect
                selector={handleIDType}
                defaultValue={initialValues?.typeIdDocument}
              >
                {
                  docTypesFetched.map(element => (
                    <option
                      key={element.identDocId}
                      value={element.identDocId}
                      className="CustomSelectOptionsItem"
                    >
                      {element.identDocName}
                    </option>
                  ))
                }
              </CustomSelect>
            </div>
            <div>
              <p>{scriptsSave && scriptsSave.userPQRSEditString ? scriptsSave.userPQRSEditString.gender.title : ''}</p>
              <CustomSelect
                selector={handleGenderType}
                defaultValue={initialValues?.gender}
              >
                {
                  gendersFetched.map(element => (
                    <option
                      key={element.genderId}
                      value={element.genderId}
                      className="CustomSelectOptionsItem"
                    >
                      {element.genderType}
                    </option>
                  ))
                }
              </CustomSelect>
            </div>
          </div>
          <div>
            <p>{scriptsSave && scriptsSave.userPQRSEditString ? scriptsSave.userPQRSEditString.expeditionPlace : ''}</p>
            <input
              type="text"
              className={`${
                incorrectInputFormat.documentExpeditionCityFormat ===
                'employeeRightFormat'
                  ? 'editUserInput'
                  : 'editUserInputIncorrectFormat'
              }`}
              value={newValues?.documentExpeditionCity}
              onChange={(e) =>
                onExpeditionCityChange(e, newValues, setNewValues)
              }
              onBlur={() =>
                onIsValidExpeditionCityBlur(
                  newValues,
                  setNewValues,
                  incorrectInputFormat,
                  setIncorrectInputFormat,
                  citiesFetched
                )
              }
            />
            <span className={incorrectInputFormat.documentExpeditionCityFormat}>
              {incorrectInputFormat.documentExpeditionCityMessage}
            </span>
          </div>
          <div>
            <p>{scriptsSave && scriptsSave.userPQRSEditString ? scriptsSave.userPQRSEditString.idNumber : ''}</p>
            <input
              type="text"
              className={`${
                incorrectInputFormat.documentNumberFormat ===
                'employeeRightFormat'
                  ? 'editUserInput'
                  : 'editUserInputIncorrectFormat'
              }`}
              value={newValues?.documentNumber}
              onChange={(e) =>
                onDocumentNumberChange(e, newValues, setNewValues)
              }
              onBlur={() =>
                onIsValidDocumentNumberBlur(
                  dataContext,
                  newValues,
                  setNewValues,
                  incorrectInputFormat,
                  setIncorrectInputFormat,
                  idToken,
                  initialValues
                )
              }
            />
            <span className={incorrectInputFormat.documentNumberFormat}>
              {incorrectInputFormat.documentNumberMessage}
            </span>
          </div>
        </div>
        <div className="EditPQRSUserRSide">
          <div className="EditPQRSUserDoubleInput">
            <div>
              <p>{scriptsSave && scriptsSave.userPQRSEditString ? scriptsSave.userPQRSEditString.phone : ''}</p>
              <input
                type="text"
                className={`${
                  incorrectInputFormat.phoneFormat === 'employeeRightFormat'
                    ? 'editUserInput'
                    : 'editUserInputIncorrectFormat'
                }`}
                value={newValues?.phone}
                onChange={(e) => onPhoneChange(e, newValues, setNewValues)}
                onBlur={() =>
                  onIsValidPhoneBlur(
                    dataContext,
                    newValues,
                    setNewValues,
                    incorrectInputFormat,
                    setIncorrectInputFormat,
                    idToken,
                    initialValues
                  )
                }
              />
              <span className={incorrectInputFormat.phoneFormat}>
                {incorrectInputFormat.phoneMessage}
              </span>
            </div>
            <div>
              <p>{scriptsSave && scriptsSave.userPQRSEditString ? scriptsSave.userPQRSEditString.birthdate : ''}</p>
              <input
                type="date"
                id="birthdate"
                className={`${
                  incorrectInputFormat.birthdayDateFormat ===
                  'employeeRightFormat'
                    ? 'editUserInputDate'
                    : 'editUserInputDateIncorrectFormat'
                }`}
                min={minBirthdayDate}
                max={maxBirthdayDate}
                value={newValues?.birthdayDate?.slice(0, 10)}
                onChange={(e) =>
                  onBirthdayDateChange(e, newValues, setNewValues)
                }
                onBlur={() =>
                  onIsValidBirthdayDateBlur(
                    newValues,
                    incorrectInputFormat,
                    setIncorrectInputFormat,
                    maxBirthdayDate,
                    minBirthdayDate
                  )
                }
              />
            </div>
          </div>
          <div>
            <p>{scriptsSave && scriptsSave.userPQRSEditString ? scriptsSave.userPQRSEditString.email : ''}</p>
            <input
              type="text"
              className={`${
                incorrectInputFormat.emailFormat === 'employeeRightFormat'
                  ? 'editUserInput'
                  : 'editUserInputIncorrectFormat'
              }`}
              defaultValue={initialValues?.email}
              value={newValues?.email}
              onChange={(e) => onEmailChange(e, newValues, setNewValues)}
              onInput={(e) => maxSize(e, 'email')}
              onBlur={() =>
                onIsValidEmailBlur(
                  dataContext,
                  newValues,
                  setNewValues,
                  incorrectInputFormat,
                  setIncorrectInputFormat,
                  idToken,
                  initialValues
                )
              }
            />
            <span className={incorrectInputFormat.emailFormat}>
              {incorrectInputFormat.emailMessage}
            </span>
          </div>
          <div>
            <p>{scriptsSave && scriptsSave.userPQRSEditString ? scriptsSave.userPQRSEditString.state : ''}</p>
            <CustomSelect
              selector={handleStateSelected}
              defaultValue={initialValues?.city?.stateId?.stateId}
            >
              {statesFetched.map((state) => (
                <option
                  key={state.stateId}
                  className="CustomSelectOptionsItem"
                  value={state.stateId}
                >
                  {state.stateName}
                </option>
              ))}
            </CustomSelect>
          </div>
          <div>
            <p>{scriptsSave && scriptsSave.userPQRSEditString ? scriptsSave.userPQRSEditString.city : ''}</p>
            <CustomSelect
              selector={handleCitySelected}
              defaultValue={newValues?.city?.cityId || newValues.country}
            >
              {citiesFiltered.map((city) => (
                <option
                  key={city.cityId}
                  className="CustomSelectOptionsItem"
                  value={city.cityId}
                >
                  {city.cityName}
                </option>
              ))}
            </CustomSelect>
          </div>
        </div>
      </div>
      <div className="navFooter">
        <button
          className="navFooterBackButton"
          onClick={(e) =>
            cancelEdit(e, isDisabled, handleCancelModalShow, navigate, state)
          }
        >
          <img
            className="navFooterBackLinkImage"
            src={backIcon}
            alt={scriptsSave && scriptsSave.userPQRSEditString ? scriptsSave.userPQRSEditString.altImageTxt2 : ''}
          />
          <span className="navFooterBackLinkText">
            {scriptsSave && scriptsSave.navFooterStrings ? scriptsSave.navFooterStrings.goBack : ''}
          </span>
        </button>
        <div className="navFooterButtonsContainer">
          <button
            className="EditPQRSUserBtn"
            onClick={(e) =>
              cancelEdit(e, isDisabled, handleCancelModalShow, navigate, state)
            }
            disabled={isDisabled}
          >
            {scriptsSave && scriptsSave.userPQRSEditString ? scriptsSave.userPQRSEditString.cancel : ''}
          </button>
          <button
            className="EditPQRSUserBtn"
            onClick={() =>
              handleSubmit(
                idToken,
                newValues,
                initialValues,
                handleCancelModalClose,
                handleSaveModalShow,
                dataContext,
                setIsDisabled,
                setIncorrectInputFormat,
                incorrectInputFormat
              )
            }
            disabled={isDisabled}
          >
            {scriptsSave && scriptsSave.userPQRSEditString ? scriptsSave.userPQRSEditString.save : ''}
          </button>
        </div>
      </div>
      <Modal isOpen={showSaveModal}>
        <Modal.Content>
          <p>{scriptsSave && scriptsSave.userPQRSEditString ? scriptsSave.userPQRSEditString.saveModalMessage : ''}</p>
        </Modal.Content>
        <Modal.Buttons>
          <button className="Modal-orangeBtn" onClick={() => navigate('../SolicitudDelUsuario', { state })}>
            {scriptsSave && scriptsSave.userPQRSEditString ? scriptsSave.userPQRSEditString.continue : ''}
          </button>
        </Modal.Buttons>
      </Modal>
      <Modal isOpen={showCancelModal}>
        <Modal.Content>
          <p>{scriptsSave && scriptsSave.userPQRSEditString ? scriptsSave.userPQRSEditString.cancelModalMessage : ''}</p>
        </Modal.Content>
        <Modal.Buttons>
          <button className="Modal-normalBtn" onClick={() => navigate('../SolicitudDelUsuario', { state })}>
            {scriptsSave && scriptsSave.userPQRSEditString ? scriptsSave.userPQRSEditString.cancel : ''}
          </button>
          <button
            className="Modal-orangeBtn"
            onClick={() =>
              handleSubmit(
                idToken,
                newValues,
                initialValues,
                handleCancelModalClose,
                handleSaveModalShow
              )
            }
          >
            {scriptsSave && scriptsSave.userPQRSEditString ? scriptsSave.userPQRSEditString.saveChanges : ''}
          </button>
        </Modal.Buttons>
      </Modal>
    </section>
  )
}
