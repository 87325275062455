import { dataTypes } from '../../../../context/reducers/dataReducer/dataTypes'
import { passwordEncrypt } from '../../../../utils/functions'
import { createNewUserString } from '../../../../utils/strings'
import {
  createEmployeeCredentialQuery,
  createEmployeeQuery,
  createPersonQuery,
  getFormData
} from '../../../../utils/userQueries'
import {
  activateRequestButton,
  handleRequestButton
} from '../../../../utils/validateFunctions'
import {
  onIsValidBirthdayDateBlur,
  onIsValidCityBlur,
  onIsValidCountryBlur,
  onIsValidDocumentNumberBlurEmployee,
  onIsValidDocumentTypeId,
  onIsValidEmailBlur,
  onIsValidExpeditionCityBlur,
  onIsValidGender,
  onIsValidLastNameBlur,
  onIsValidNameBlur,
  onIsValidPasswordBlur,
  onIsValidPasswordConfirmationBlur,
  onIsValidPhoneBlur
} from '../../utils/createAndEditUserMethods'

// --------------------------------------------------------------------------------------------
// Registration methods
export const createNewUserSendForm = async (
  handleShow,
  formValues,
  setFormValues,
  incorrectInputFormat,
  setIncorrectInputFormat,
  countriesFetched,
  citiesFetched,
  maxBirthdayDate,
  minBirthdayDate,
  roleIdToBeCreated,
  idToken,
  dataContext,
  dataDispatch,
  isRequesting,
  setIsRequesting,
  handleShow3,
  setModal3Message
) => {
  if (isRequesting) return

  const timeOutId1 = handleRequestButton(setIsRequesting)
  const isValidFormData = await validateDataBeforeCreateEmployee(
    formValues,
    setFormValues,
    incorrectInputFormat,
    setIncorrectInputFormat,
    countriesFetched,
    citiesFetched,
    maxBirthdayDate,
    minBirthdayDate,
    idToken,
    dataContext
  )

  if (!isValidFormData) {
    activateRequestButton(setIsRequesting, timeOutId1)
    return
  }

  const {
    name,
    lastName,
    documentNumber,
    birthdayDate,
    phone,
    documentTypeId,
    gender,
    cityId,
    documentExpeditionCity,
    email,
    passwordConfirmation
  } = formValues

  if (name.length < 3) {
    setModal3Message(createNewUserString.wrongNameLength)
    handleShow3()
    return
  }

  if (lastName.length < 3) {
    setModal3Message(createNewUserString.wrongLastnameLength)
    handleShow3()
    return
  }

  const createPersonQueryBody = {
    personName: name,
    personLastName: lastName,
    personIdentNum: documentNumber,
    personBirthDt: birthdayDate,
    personPhone: phone,
    personActive: true,
    identDocId: documentTypeId,
    genderId: gender,
    personCity: cityId,
    expeditionCity: documentExpeditionCity
  }

  const timeOutId2 = handleRequestButton(setIsRequesting, timeOutId1)
  let createPersonResponse = null
  if (!formValues.personId) {
    createPersonResponse = await createPersonQuery(createPersonQueryBody, idToken, dataContext)
    if (!createPersonResponse.response) return
  }

  const personId = createPersonResponse ? createPersonResponse.data.personId : formValues.personId

  const createEmployeeQueryBody = { roleId: roleIdToBeCreated, personId }
  const timeOutId3 = handleRequestButton(setIsRequesting, timeOutId2)
  const createEmployeeResponse = await createEmployeeQuery(createEmployeeQueryBody, idToken, dataContext)
  if (!createEmployeeResponse.response) return

  const { employeeId } = createEmployeeResponse.body

  const passwordEncrypted = passwordEncrypt(passwordConfirmation)

  const createEmployeeCredentialQueryBody = {
    employeeCredentialEmail: email,
    employeeCredentialPass: passwordEncrypted,
    employeeId
  }

  handleRequestButton(setIsRequesting, timeOutId3)
  const createEmployeeCredentialResponse = await createEmployeeCredentialQuery(createEmployeeCredentialQueryBody, idToken, dataContext)
  if (!createEmployeeCredentialResponse.response) return

  const action = {
    type: dataTypes.addEmployeeToEmployeesList,
    payload: {
      employeeCredentialEmail: createEmployeeCredentialResponse.body.employeeCredentialEmail,
      employeeCredentialId: createEmployeeCredentialResponse.body.employeeCredentialId,
      employeeId: {
        employeeId: createEmployeeCredentialResponse.body.employeeId,
        roleId: {
          roleId: createEmployeeResponse.body.roleId,
          roleName: createEmployeeResponse.body.roleName
        },
        personId: {
          personId: createPersonResponse ? createPersonResponse.data.personId : formValues.personId
        }
      }
    }
  }

  dataDispatch(action)
  handleShow()
}

// --------------------------------------------------------------------------------------------
// Other methods
export const setClassAndIcon = (roles, userProfile, iconPersonCircleAdmin, iconPersonCircleLawyer, iconPersonCircleOper) => {
  const colorClasses = {
    [roles.ADMINISTRATIVO]: 'adminColor',
    [roles.ABOGADO]: 'lawyerColor',
    default: 'operativeColor'
  }
  const colorClass = colorClasses[userProfile] || colorClasses.default

  const iconMap = {
    [roles.ADMINISTRATIVO]: iconPersonCircleAdmin,
    [roles.ABOGADO]: iconPersonCircleLawyer,
    default: iconPersonCircleOper
  }
  const iconSrc = iconMap[userProfile] || iconMap.default

  return {
    colorClass,
    iconSrc
  }
}

export const closeModal = (navigate, handleClose) => {
  navigate('../NuevoUsuario')
  handleClose()
}

export const initializeFormData = async (citiesDocTypesGenders, dataDispatch) => {
  const { countriesFetched, citiesFetched, docTypesFetched, gendersFetched } = citiesDocTypesGenders

  try {
    if (citiesFetched.length < 1 | docTypesFetched.length < 1 || gendersFetched.length < 1 || countriesFetched.length < 1) {
      const { cities, genders, docTypes, countries } = await getFormData()
      const action = {
        type: dataTypes.citiesDocTypesGenders,
        payload: {
          countriesFetched: countries,
          citiesFetched: cities,
          docTypesFetched: docTypes,
          gendersFetched: genders
        }
      }
      dataDispatch(action)
    }
  } catch (error) {
    console.error(error)
  }
}

const validateDataBeforeCreateEmployee = async (
  formValues,
  setFormValues,
  incorrectInputFormat,
  setIncorrectInputFormat,
  countriesFetched,
  citiesFetched,
  maxBirthdayDate,
  minBirthdayDate,
  idToken,
  dataContext
) => {
  return (
    onIsValidNameBlur(formValues, incorrectInputFormat, setIncorrectInputFormat) &&
    onIsValidLastNameBlur(formValues, incorrectInputFormat, setIncorrectInputFormat) &&
    await onIsValidEmailBlur(dataContext, formValues, setFormValues, incorrectInputFormat, setIncorrectInputFormat, idToken) &&
    onIsValidCountryBlur(formValues, setFormValues, incorrectInputFormat, setIncorrectInputFormat, countriesFetched) &&
    onIsValidCityBlur(formValues, setFormValues, incorrectInputFormat, setIncorrectInputFormat, citiesFetched) &&
    onIsValidPasswordBlur(formValues, incorrectInputFormat, setIncorrectInputFormat) &&
    onIsValidDocumentTypeId(formValues, incorrectInputFormat, setIncorrectInputFormat) &&
    await onIsValidDocumentNumberBlurEmployee(dataContext, formValues, setFormValues, incorrectInputFormat, setIncorrectInputFormat, idToken) &&
    onIsValidBirthdayDateBlur(formValues, incorrectInputFormat, setIncorrectInputFormat, maxBirthdayDate, minBirthdayDate) &&
    onIsValidExpeditionCityBlur(formValues, setFormValues, incorrectInputFormat, setIncorrectInputFormat, citiesFetched) &&
    onIsValidGender(formValues, incorrectInputFormat, setIncorrectInputFormat) &&
    await onIsValidPhoneBlur(dataContext, formValues, setFormValues, incorrectInputFormat, setIncorrectInputFormat, idToken) &&
    onIsValidPasswordConfirmationBlur(formValues, incorrectInputFormat, setIncorrectInputFormat)
  )
}
